<template>
  <el-card class="json-config" v-if="ready">
    <el-alert
      class="m-b-10"
      title="提示"
      type="info"
      :closable="false"
      description="当前数据是副本数据，数据同步是将数据同步到全局，不代表保存。为防止数据出现问题，修改完的数据会重新合并，可能发生变化。"
      show-icon
    />
    <vue-json-editor
      v-model="json"
      mode="code"
      lang="zh"
      :showBtns="true"
      :expandedOnStart="true"
      style="height: 600px;"
      @json-save="onJsonSave"
    ></vue-json-editor>
  </el-card>
  <el-card class="question-config" v-else v-loading="true"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  />
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import { removeIfEmpty } from '@/scripts/helper';
import _ from 'lodash';

export default {
  name: 'json-config',
  props: {
    styleData: {
      required: true,
      type: Object
    }
  },
  components: {
    vueJsonEditor
  },
  mounted () {
    setTimeout(() => {
      this.formatJson();
      this.ready = true;
    }, 200);
  },
  watch: {
    styleData: {
      deep: true,
      handler (val) {
        this.formatJson(val);
      }
    }
  },
  data () {
    return {
      json: null,
      ready: false
    };
  },
  methods: {
    formatJson (val) {
      const config = removeIfEmpty(val || this.styleData);
      this.json = config;
    },
    onJsonSave (val) {
      this.$emit('changeJson', _.cloneDeep(val));
      this.$message.success('操作成功');
    }
  }
};
</script>
<style>
.jsoneditor-poweredBy{
    display: none;
}
</style>
<style lang="scss" scoped>
  .json-config {
    overflow: hidden;
    /deep/ .el-card__body {
      height: 100%;
      overflow: hidden;
    }
    /deep/.jsoneditor-vue{
      height: calc(100% - 50px);
    }
    /deep/.json-save-btn{
      margin-top: 10px;
      cursor: pointer;
      width: 140px;
      line-height: 30px;
      font-size: 14px;
      color: transparent;
      position: relative;
      &:after {
        content: '数据同步';
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
